.portfolio__container {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    gap: 2.5rem;
}

.portfolio__item {
    background: var(--color-dark-blue);
    padding: 1.2rem;
    border-radius: 1rem;
    border: 1px solid transparent;
    transition: var(--transition);
}

.portfolio__item:hover {
    border-color:var(--color-blue);
    background:transparent;
}

.portfolio__item:hover h3 {
    color: var(--color-blue);
    transition: color 400ms ease;
}

.portfolio__item-video {
    text-align: center;
    height:25rem;
}

.portfolio__item-video iframe,img {
    border-radius: 1rem;
    display: block;
    width: 100%;
    height:100%;
    object-fit:fill;
}

.portfolio__item-video img{
    display:block;
}

.portfolio__item-data {
}

.portfolio__item h3 {
    margin: 1rem 0 1rem;
}

.portfolio__item h5 {
    padding-bottom: 0.1rem;
}


.portfolio__item-topics {
    padding-top: 1rem;
}

.portfolio__item-topics small {
    margin: 1rem 0 1rem;
    color: var(--color-light);
}

/* ====================== Media Queries ======================*/
@media screen and (max-width:1024px) {
    .portfolio__container {
        grid-template-columns: 1fr;
        gap: 1.2rem;
    }
}

/* ====================== Small Devices ======================*/
@media screen and (max-width:600px) {
    .portfolio__container {
        grid-template-columns: 1fr;
        gap: 1rem;
    }

    .portfolio__item {
        padding: 1rem;
    }

    .portfolio__item-video {
        height: 15rem;
    }
}