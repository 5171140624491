nav{
    background: rgba(0, 0, 0, 0.6);
    width: max-content;
    display:block;
    padding: 0.7rem 1.7rem;
    z-index: 2;
    position: fixed;
    left: 50%;
    transform: translate(-50%);
    bottom: 2rem;
    display: flex;
    gap:0.8rem;
    border-radius: 4rem;
    backdrop-filter: blur(15px);
}

nav a{
    display: flex;
    background: transparent;
    padding: 0.5rem;
    border-radius:50%;
    color: var(--color-light);
    font-size:1.1rem;
}

nav a:hover {
    background: rgba(0,0,0,0.4);
}

nav a.active {
    background: rgba(0,0,0,1);
    color: var(--color-white);
}