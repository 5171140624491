.content-background {
    background: var(--color-dark-blue);
    padding: 1.2rem;
    border-radius: 1.2rem;
    border: 1px solid transparent;
}

.content-background > h3 {
    color: var(--color-blue);
}
