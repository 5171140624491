.card-background {
    background: var(--color-dark-blue);
    padding: 1.2rem;
    border-radius: 1.2rem;
    text-align: center;
    border: 1px solid transparent;
    transition: var(--transition);
}

.card-background:hover {
    background: transparent;
    border-color: var(--color-blue);
}

.card-background.card_layout {
    display: grid;
    grid-template-columns: 8% 85%;
    gap: 2%;
}

.card-img {
    width: 96px;
    height: 96px;
    margin: auto;
}

.card-text{
    text-align: left;
    margin-top:auto;
    margin-bottom:auto;
}

.card-text-description {
    color: var(--color-white);
}

/* ====================== Media Queries ======================*/
@media screen and (max-width:1600px) {
    .card-background.card_layout {
        gap: 3%;
    }
}

@media screen and (max-width:1280px) {
    .card-background.card_layout {
        grid-template-columns: 1fr;
        gap: 2rem;
    }
}

/* ====================== Small Devices ======================*/
@media screen and (max-width:600px) {
    .card-background.card_layout {
        width: var(--container-width-sm);
    }
}