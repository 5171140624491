.services__container {
    display:grid;
    grid-template-columns: repeat(2,1fr);
    gap: 3rem;
}

.services {
    background: var(--color-dark-blue);
    border-radius: 0 0 2rem 2rem;
    border: 1px solid transparent;
    height: fit-content;
    transition: var(--transition);
}

.services:hover {
    background: transparent;
    border-color: var(--color-blue);
    cursor: default;
}

.service__head {
    background: var(--color-blue);
    padding: 1.7rem;
    border-radius: 0 0 2rem 2rem;
    box-shadow: 0 2rem 1rem rgba(0,0,0,0.1);
}

.service__head > h3 {
    color : var(--color-bg-darkest-blue);
    font-size: 1rem;
    text-align: center;
}

.service__list{
    padding: 2rem;
}

.service__list > li {
    display: flex;
    gap:1rem;
    margin-bottom: 0.8rem;
}

.service__list-icon {
    color: var(--color-blue);
    margin-top: 2px;
}

.service__list p {

}

/* ====================== Media Queries ======================*/
@media screen and (max-width:1024px) {
    .services__container {
        gap: 2rem;
    }

    .services {
        height: auto;
    }
}

/* ====================== Small Devices ======================*/
@media screen and (max-width:600px) {
    .services__container {
        grid-template-columns: 1fr;
        gap:1.5rem;
    }
}
