.backgroundRender {
    position: absolute;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    z-index: -1;
}

.backgroundRender > canvas {
    width: 100%;
    height: 100%;
}
