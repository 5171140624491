header{
    height: 100vh;
    padding-top: 7rem;
    overflow: hidden;
}

.header__container{
    text-align:center;
    height: 100%;
    position: relative;
}

/* ====================== CTA ======================*/
.cta{
    margin-top :2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
    padding-right:10px;
}

.cta__contents{
    display:flex;
    gap: 0.2rem;
}

.cta__locked {
    pointer-events: none;
}

.cta__content-stack {
    display: grid;
    padding: 0;
}

.cta__icon {
    margin-top: 0.3rem;
}

.cta__content-stack > * {
    grid-row: 1;
    grid-column: 1;
}

.cta__content-layer1, .cta__content-layer3 {
    padding: 0.75rem 1.2rem;
}

.cta__content-layer1 {
    filter: brightness(30%);
    z-index:0;
}

.cta__content-layer2 {
    object-fit: fill;
    margin: 0;
    padding: 0;
    background: linear-gradient(transparent 5%, rgb(0,0,0,0.9));
    border-radius: 0.4rem;
    z-index: 1;
}

.cta__content-layer3 {
    justify-content: center;
    margin-top: 0.3rem;
    z-index: 2;
}
/* ====================== Socials ======================*/
.header__socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 0;
    bottom: 3rem;
}

.header__socials::after {
    content: '';
    width: 1px;
    height:2rem;
    background: var(--color-white);
}

/* ====================== Image ======================*/
.profile_img{
    width: 100%;
    height:100%;
    position:absolute;
    padding: 1rem 1.0rem 1.0rem 1.0rem;
}

/* ====================== Media Queries ======================*/
@media screen and (max-width:1024px) {
    .header{
        height:68vh;
    }
}

/* ====================== Small Devices ======================*/
@media screen and (max-width:600px) {
    .header {
        height: 100vh;
    }
    .cta {
        gap: 0.7rem;
        padding-right: 0px;
    }
    .cta__contents {
        font-size: 0.9rem;
        gap: 0.0rem;
    }
}