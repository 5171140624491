footer {
    background: var(--color-blue);
    padding: 1rem 0;
    text-align:center;
    font-size:0.9rem;
    margin-top: 5rem;
}

footer a{
    color: var(--color-bg-darkest-blue);
}

.footer__logo{
    font-size:1.6rem;
    font-weight:500;
    margin-bottom:0.2rem;
    display:inline-block;
}

.permalinks {
    display:flex;
    flex-wrap:wrap;
    justify-content:center;
    gap:2rem;
    margin:0 auto 0.7rem;
}

.footer__socials {
    display:flex;
    justify-content:center;
    gap:1rem;
    margin-bottom: 5rem;
}

.footer__socials a {
    font-size:1.2rem;
    background: var(--color-bg-darkest-blue);
    color: var(--color-white);
    padding: 0.05rem;
    display:flex;
    border: 1px solid transparent;
    border-radius: 0.2rem;
}


.footer__socials a:hover {
    background: transparent;
    color: var(--color-bg-darkest-blue);
    border-color: var(--color-bg-darkest-blue);
}

.footer__copyright{
    margin-bottom:4rem;
    color:var(--color-bg-darkest-blue);
}

/* ====================== Small Devices ======================*/
@media screen and (max-width:600px) {
    .permalinks{
        gap:1rem;
    }
}