@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
}

:root {
    --color-bg-darkest-blue: #030f21;
    --color-dark-blue: #152c4b;
    --color-blue: #0aa8c7;
    --color-red: #d32c40;
    --color-white: #efefef;
    --color-light: rgba(255,255,255,0.6);
    --container-width-lg: 75%;
    --container-width-md: 86%;
    --container-width-sm: 90%;
    --transition: all 400ms ease;
}

html{
    scroll-behavior: smooth;
}

::-webkit-scrollbar{
    display:none;
}

body {
    font-family: "Poppins", sans-serif;
    background: var(--color-bg-darkest-blue);
    color: var(--color-white);
    line-height: 1.7;
}

/* ====================== General Styles ======================*/
.container{
    width: var(--container-width-lg);
    margin: 0 auto;
}

h1, h2, h3, h4, h5{
    font-weight: 500;
}

h1 {
    font-size: 2.5rem;
}

section{
    margin-top: 8rem;
}

section > h2, section > h5{
    text-align: center;
}

section > h2{
    color: var(--color-blue);
    margin-bottom: 3rem;
}

a {
    color: var(--color-blue);
    transition: var(--transition);
}

a:hover{
    color: var(--color-white);
}

.btn {
    width: max-content;
    display: inline-block;
    color: var(--color-blue);
    padding: 0.75rem 1.2rem;
    border-radius: 0.4rem;
    cursor: pointer;
    border: 1px solid var(--color-blue);
    transition: var(--transition);
}

.btn:hover {
    background: var(--color-white);
    color: var(--color-bg-darkest-blue);
    border-color: transparent;
}

.btn-primary {
    background: var(--color-blue);
    color: var(--color-bg-darkest-blue);
}

img{
    display: block;
    width: 100%;
    object-fit: cover;
}

.text-light{
    color: var(--color-light);
}
/* ====================== Media Queries ======================*/
@media screen and (max-width:1024px) {
    .container {
        width: var(--container-width-md);
    }

    section{
        margin-top: 6rem;
    }
}

/* ====================== Small Devices ======================*/
@media screen and (max-width:600px) {
    .container {
        width: var(--container-width-sm);
    }

    section > h2{
        margin-bottom: 2rem;
    }

    .btn {
        padding: 0.7rem 0.7rem;
    }
}